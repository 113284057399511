import {
	isAppPerformanceCreated,
	isPerformanceCheckEnabled,
} from '@CaseOne/Common/utilities/performance-check-utils/performance-check-utils';

export function setSourcesInitializedTiming (win: Window): void {
	if (isPerformanceCheckEnabled(win)) {
		const time = win.performance.now();

		isAppPerformanceCreated(win);
		win.AppPerformance.timings.sourcesInitialized = time;
		// tslint:disable:no-console
		console.info(`Timing 'sourcesInitialized' is ${time}`);
	}
}
