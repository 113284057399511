import {
	ICommonContentLoader,
	ICommonContentLoaderError,
	ICommonContentLoaderParams,
} from './interfaces/common-content-loader.interface';

export const COMMON_CONTENT_LOADER_CLASSES = {
	LOADER_CONTAINER: 'b-common_content_loader-container',
	PROGRESS_OVERLAY: 'b-common_content_loader-progress-overlay',
	PROGRESS_OVERLAY_ANIMATION_RUN_CLASS: 'b-common_content_loader-progress-overlay--run',
	PROGRESS_BODY_OVERFLOW: 'b-common_content_loader-body_overflow',
	POSITION_ABSOLUTE: 'b-common_content_loader-progress-overlay--absolute',
	OPAQUE: 'b-common_content_loader-progress-overlay--opaque',
	PROGRESS_WRAPPER: 'b-common_content_loader-wrapper',
	PROGRESS_LABEL: 'b-common_content_loader-progress-label',
	PROGRESS_ELEMENT: 'b-common_content_loader-progress',
	PROGRESS_INDETERMINATE: 'b-common_content_loader-progress-indeterminate',
	LOADING_ERROR: 'b-common_content_loader-error',
	LOADING_ERROR_DESCRIPTION: 'b-common_content_loader-error_description',
	LOADING_ERROR_TIMER: 'b-common_content_loader-error_timer',
};

export class CommonContentLoader implements ICommonContentLoader {
	private static body: HTMLElement = document.body;

	private readonly parentElement: HTMLElement;
	private readonly container: HTMLElement;
	private params: ICommonContentLoaderParams;
	private labelElement: HTMLElement;
	private wrapperElement: HTMLElement;
	private errorElement: HTMLElement;
	private errorDescriptionElement: HTMLElement;
	private errorTimerElement: HTMLElement;
	private isEnabled: boolean;
	private labelText: string;

	constructor(params: ICommonContentLoaderParams = {
		isPositionAbsolute: false,
		isOpaque: false,
		label: '',
	}) {
		this.params = params;
		this.parentElement = this.params.container || document.body;
		this.container = document.createElement('div');
		this.labelText = this.params.label || '';

		if (!this.params.container) {
			this.container.className = COMMON_CONTENT_LOADER_CLASSES.LOADER_CONTAINER;
		}
	}

	public enable() {
		if (this.isEnabled) {
			return;
		}

		this.parentElement.appendChild(this.container);

		const containerClasses = [
			COMMON_CONTENT_LOADER_CLASSES.PROGRESS_OVERLAY,
		];

		if (this.params.isPositionAbsolute) {
			containerClasses.push(COMMON_CONTENT_LOADER_CLASSES.POSITION_ABSOLUTE);
		}

		if (this.params.isOpaque) {
			containerClasses.push(COMMON_CONTENT_LOADER_CLASSES.OPAQUE);
		}

		this.container.className += ' ' + containerClasses.join(' ');
		this.container.appendChild(this.getTemplate(this.labelText));

		if (!this.params.isPositionAbsolute) {
			CommonContentLoader.body.classList.add(COMMON_CONTENT_LOADER_CLASSES.PROGRESS_BODY_OVERFLOW);
		}

		setTimeout(() => {
			this.container.classList.add(COMMON_CONTENT_LOADER_CLASSES.PROGRESS_OVERLAY_ANIMATION_RUN_CLASS);
		});

		this.isEnabled = true;
	}

	public disable() {
		if (!this.isEnabled) {
			return;
		}

		this.container.classList.remove(COMMON_CONTENT_LOADER_CLASSES.PROGRESS_OVERLAY);
		this.container.classList.remove(COMMON_CONTENT_LOADER_CLASSES.POSITION_ABSOLUTE);
		this.container.classList.remove(COMMON_CONTENT_LOADER_CLASSES.PROGRESS_OVERLAY_ANIMATION_RUN_CLASS);
		this.container.textContent = '';
		this.container.parentNode.removeChild(this.container);

		CommonContentLoader.body.classList.remove(COMMON_CONTENT_LOADER_CLASSES.PROGRESS_BODY_OVERFLOW);

		this.isEnabled = false;
	}

	public setLabel(label) {
		this.labelText = label;

		if (this.labelElement) {
			this.labelElement.innerHTML = label;
		}
	}

	public error(content: ICommonContentLoaderError) {
		if (!this.isEnabled) {
			return;
		}

		const fragment = document.createDocumentFragment();

		if (content.text) {
			if (!this.errorElement) {
				this.errorElement = document.createElement('div');
				this.errorElement.className = COMMON_CONTENT_LOADER_CLASSES.LOADING_ERROR;

				fragment.appendChild(this.errorElement);
			}

			this.errorElement.innerHTML = content.text;
		}

		if (content.description) {
			if (!this.errorDescriptionElement) {
				this.errorDescriptionElement = document.createElement('div');
				this.errorDescriptionElement.className = COMMON_CONTENT_LOADER_CLASSES.LOADING_ERROR_DESCRIPTION;

				fragment.appendChild(this.errorDescriptionElement);
			}

			this.errorDescriptionElement.innerHTML = content.description;
		}

		if (content.timer) {
			if (!this.errorTimerElement) {
				this.errorTimerElement = document.createElement('div');
				this.errorTimerElement.className = COMMON_CONTENT_LOADER_CLASSES.LOADING_ERROR_TIMER;

				fragment.appendChild(this.errorTimerElement);
			}

			this.errorTimerElement.innerHTML = content.timer;
		}

		if (fragment.hasChildNodes()) {
			this.container.appendChild(fragment);
		}
	}

	private getTemplate(label: string): DocumentFragment {
		const fragment = document.createDocumentFragment();

		this.wrapperElement = document.createElement('div');
		this.labelElement = document.createElement('div');
		this.labelElement.innerHTML = label;

		const progressElement = document.createElement('div');
		const indeterminateElement = document.createElement('div');

		this.wrapperElement.className = COMMON_CONTENT_LOADER_CLASSES.PROGRESS_WRAPPER;
		this.labelElement.className = COMMON_CONTENT_LOADER_CLASSES.PROGRESS_LABEL;
		progressElement.className = COMMON_CONTENT_LOADER_CLASSES.PROGRESS_ELEMENT;
		indeterminateElement.className = COMMON_CONTENT_LOADER_CLASSES.PROGRESS_INDETERMINATE;

		progressElement.appendChild(indeterminateElement);

		this.wrapperElement.appendChild(this.labelElement);
		this.wrapperElement.appendChild(progressElement);

		fragment.appendChild(this.wrapperElement);

		return fragment;
	}
}

// tslint:disable-next-line:no-string-literal
window['CommonContentLoader'] = CommonContentLoader;
